import { type MetaFunction } from '@remix-run/node'
import { useNavigate } from '@remix-run/react'
import { SearchBar } from '#app/components/headerFooter/header/search-bar'
// import { cn } from '#app/utils/misc'
import { Button } from '../components/ui/radixUiTheme/Button'
import { Card, InnerFullCard } from '../components/ui/radixUiTheme/Card'
import { TextElem } from '../components/ui/radixUiTheme/Typography'
import { APP_NAME } from '../constants'

export const meta: MetaFunction = () => [{ title: APP_NAME }]

// type SearchSelectorState =
// 	| 'Search...'
// 	| 'Buy Leads...'
// 	| 'Sell Leads...'
// 	| 'Buy Equipment...'
// 	| 'Sell Equipment...'

// type SearchNavBarProps = {
// 	searchSelectorState: SearchSelectorState
// 	setSearchSelectorState: React.Dispatch<
// 		React.SetStateAction<SearchSelectorState>
// 	>
// 	expectedButtonState: SearchSelectorState
// 	text: string
// }

// function SearchBarNavButton({
// 	searchSelectorState,
// 	setSearchSelectorState,
// 	expectedButtonState,
// 	text,
// }: SearchNavBarProps) {
// 	const onClickHandler = () => {
// 		setSearchSelectorState(expectedButtonState)
// 	}

// 	return (
// 		<Button
// 			className={cn(
// 				'text-white',
// 				searchSelectorState === expectedButtonState
// 					? 'border-none underline'
// 					: '',
// 			)}
// 			variant="outline"
// 			size="1"
// 			onClick={onClickHandler}
// 		>
// 			{text}
// 		</Button>
// 	)
// }

export default function Index() {
	const navigate = useNavigate()

	return (
		<div className="flex h-full flex-1 flex-col">
			<main className="font-poppins flex h-full flex-col place-items-center p-4">
				<div className="flex min-h-[50vh] w-full justify-center rounded-lg bg-hero-image bg-cover bg-center">
					<div className="flex h-full flex-col items-center gap-4 pt-20 sm:w-[50%]">
						<TextElem className="rounded-md bg-[color-mix(in_srgb,var(--accent-5)_50%,transparent)] px-2 text-center text-h5">
							Transform Your Business Growth Today
						</TextElem>
						<SearchBar className="bg-background" />
					</div>
				</div>

				<div
					data-testid="why-business-owners-choose-us"
					className="container flex flex-col justify-between gap-8 pt-10 sm:flex-row"
				>
					<Card className="border-accent-6 flex h-auto flex-col rounded-md border-2 p-6 shadow-lg">
						<TextElem className="text-accent-9 mb-4 text-h4 font-semibold">
							Why Business Owners Choose Us
						</TextElem>
						<ul className="text-accent-11 space-y-4">
							<li className="flex items-start">
								<span className="mr-2">🎯</span>
								<span>
									Find qualified leads and expand your business reach beyond
									geographical limits
								</span>
							</li>
							<li className="flex items-start">
								<span className="mr-2">💼</span>
								<span>
									Monetize excess leads or find trusted partners when scaling or
									restructuring
								</span>
							</li>
							<li className="flex items-start">
								<span className="mr-2">🤝</span>
								<span>
									Connect with verified local businesses through our secure
									marketplace
								</span>
							</li>
							<li className="flex items-start">
								<span className="mr-2">📝</span>
								<span>
									Streamline deals with automated, professional-grade contracts
								</span>
							</li>
						</ul>
					</Card>
					<Card className="border-accent-6 flex h-auto flex-col rounded-md border-2 p-6 shadow-lg">
						<TextElem className="text-accent-9 mb-4 text-h4 font-semibold">
							Powerful Features for Growth
						</TextElem>
						<ul className="text-accent-11 space-y-4">
							<li className="flex items-start">
								<span className="mr-2">✓</span>
								<span>
									Verified Business Network: Connect with EIN and TaxID verified
									partners you can trust
								</span>
							</li>
							<li className="flex items-start">
								<span className="mr-2">✓</span>
								<span>
									Smart CRM Integration: Manage leads and communicate seamlessly
									in one platform
								</span>
							</li>
							<li className="flex items-start">
								<span className="mr-2">✓</span>
								<span>
									Local Business Mapping: Discover opportunities in your area
									with precision matching
								</span>
							</li>
							<li className="flex items-start">
								<span className="mr-2">✓</span>
								<span>
									Secure Transactions: Close deals faster with our automated
									contract system
								</span>
							</li>
						</ul>
					</Card>
				</div>
				<div data-testid="how-it-works" className="container mt-10">
					<Card className="flex flex-col">
						<TextElem className="text-accent-9 mb-12 text-center text-h3 font-semibold">
							How It Works
						</TextElem>
						<div className="grid grid-cols-1 gap-8 md:grid-cols-3">
							<InnerFullCard className="flex flex-col items-center text-center">
								<div className="bg-accent-4 mb-4 rounded-full p-4">
									<span className="text-h3">1</span>
								</div>
								<TextElem className="mb-2 text-h6 font-semibold text-[var(--accent-11)]">
									Create Your Account
								</TextElem>
								<TextElem className="text-accent-11">
									Verify your business credentials and set up your profile in
									minutes
								</TextElem>
							</InnerFullCard>
							<InnerFullCard className="flex flex-col items-center text-center">
								<div className="bg-accent-4 mb-4 rounded-full p-4">
									<span className="text-h3">2</span>
								</div>
								<TextElem className="mb-2 text-h6 font-semibold text-[var(--accent-11)]">
									Add your current leads
								</TextElem>
								<TextElem className="text-accent-11">
									Add your current leads and get personalized recommendations
								</TextElem>
							</InnerFullCard>
							<InnerFullCard className="flex flex-col items-center text-center">
								<div className="bg-accent-4 mb-4 rounded-full p-4">
									<span className="text-h3">3</span>
								</div>
								<TextElem className="mb-2 text-h6 font-semibold text-[var(--accent-11)]">
									Meet with your industry peers
								</TextElem>
								<TextElem className="text-accent-11">
									Meet your industry peers and leaders
								</TextElem>
							</InnerFullCard>
						</div>
					</Card>
				</div>
				<div
					data-testid="ready-to-grow-your-business-cta"
					className="container my-10"
				>
					<Card className="bg-accent-3 flex flex-col p-12 text-center sm:flex-row">
						<div className="flex flex-col items-center justify-center gap-4">
							<TextElem className="text-accent-12 mb-4 text-h2 font-semibold">
								Ready to Grow Your Business?
							</TextElem>
						</div>
						<div className="flex flex-col">
							<TextElem className="text-accent-11 mb-8">
								Join businesses already trading leads and equipment on our
								platform.
							</TextElem>
							<div className="flex justify-center">
								<Button
									size="3"
									className="font-semibold"
									onClick={() => {
										navigate('/signup')
									}}
								>
									Get Started Free
								</Button>
							</div>
						</div>
					</Card>
				</div>
			</main>
		</div>
	)
}
